import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";

const IndentPage = lazy(() => import("./modules/Indents/pages/indentPage"));
const RequestsPage = lazy(() => import("./modules/Indents/pages/requestsPage"));
const ProfilePage = lazy(() => import("./pages/ProfilePage"));

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/actions/indents" />
        }
        {/*
        <ContentRoute path="/dashboard" component={DashboardPage} />
        <ContentRoute path="/builder" component={BuilderPage} />
        <ContentRoute path="/my-page" component={MyPage} />
        <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />*/}
        <Route path="/actions" component={IndentPage} />
        <Route path="/requests" component={RequestsPage} />
        <ContentRoute path="/profile" component={ProfilePage} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
