export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    (config) => {
      const {
        auth: { authToken },
      } = store.getState();

      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }
      config.baseURL =
        process.env.NODE_ENV === "production"
          ? "https://vocal-framework-218415.el.r.appspot.com"
          : "http://localhost:1337";
      return config;
    },
    (err) => Promise.reject(err)
  );
}
