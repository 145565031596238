import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { customersSlice } from "../app/modules/Indents/_redux/customers/customersSlice";
import { requestsSlice } from "../app/modules/Indents/_redux/requests/requestsSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  customers: customersSlice.reducer,
  requests: requestsSlice.reducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
