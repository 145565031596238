/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { useSelector } from "react-redux";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  const { user } = useSelector((state) => state.auth);

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level
        <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        {/*end::1 Level*/}

        {/* Applications */}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">Applications</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}

        {/* eCommerce */}
        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/actions",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/actions">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Bag2.svg")} />
            </span>
            <span className="menu-text">Indents</span>
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Indents</span>
                </span>
              </li>
              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive("/actions/indents")}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/actions/indents">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Indents</span>
                </NavLink>
              </li>
              {(user.role.type === "helpdesk" ||
                user.role.type === "branch_admin") && (
                <li
                  className={`menu-item ${getMenuItemActive("/requests")}`}
                  aria-haspopup="true"
                >
                  <NavLink className="menu-link" to="/requests">
                    <i className="menu-bullet menu-bullet-dot">
                      <span />
                    </i>
                    <span className="menu-text">
                      {user.role.type === "branch_admin"
                        ? "Request Callback"
                        : "Requests"}
                    </span>
                  </NavLink>
                </li>
              )}
              {(user.role.type === "helpdesk" ||
                user.role.type === "headoffice") && (
                <li
                  className={`menu-item ${getMenuItemActive(
                    "/actions/indents"
                  )}`}
                  aria-haspopup="true"
                >
                  <div className="menu-link">
                    <i className="menu-bullet menu-bullet-dot"></i>
                    <a
                      className="menu-text"
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://vocal-framework-218415.el.r.appspot.com/admin"
                    >
                      Admin
                    </a>
                  </div>
                </li>
              )}
              {/*end::2 Level*/}
              {/*begin::2 Level*/}
            </ul>
          </div>
        </li>
        {/*end::1 Level*/}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
